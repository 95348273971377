<template>
  <FloatLabel variant="on">
    <InputText
      :id="`contact-email-${props.rowNumber}`"
      v-model="emailComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.contacts.email').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :data-testid="`contact-email-${props.rowNumber}`"
      class="w-full"
      :invalid="val.email.$error"
      maxlength="8000"
    />
    <label for="`contact-email-${props.rowNumber}`">{{ t("supplier.contacts.email") }}</label>
  </FloatLabel>

  <small v-if="val.email.$error" class="p-error" data-testid="contact-email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { email as validateEmail } from "@/locales/i18n-validators";
import { computed } from "vue";
const { t } = useI18n();

const props = defineProps<{
  email: string;
  contact: SupplierContact;
  contacts: SupplierContact[];
  rowNumber: number;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
  (e: "contacts"): void;
}>();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const isUniqueEmail = (email: string) => {
  if (email.trim().length === 0) return true;

  const duplicateContact = props.contacts.find(
    (c) => c.email.toLowerCase() === email.toLowerCase() && c.id !== props.contact.id
  );
  return duplicateContact === undefined;
};

const validateContacts = () => {
  if (props.contact.email !== "") {
    //Valid
    return true;
  }

  if (
    props.contact.firstName === "" &&
    props.contact.lastName === "" &&
    props.contact.email === "" &&
    props.contact.phoneNumber === ""
  ) {
    //Valid
    return true;
  }

  if (props.contact.firstName !== "" || props.contact.lastName !== "") {
    if (props.contact.phoneNumber !== "" || props.contact.email !== "") {
      //Valid
      return true;
    }
  }

  //Not valid
  return false;
};

const rules = {
  email: {
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
    unique: helpers.withMessage(t("validations.exists", { property: t("supplier.contacts.email") }), isUniqueEmail),
    required: helpers.withMessage(() => t("validations.email-required"), validateContacts),
  },
};

const val = useVuelidate(rules, props);
</script>
