<template>
  <FloatLabel variant="on">
    <MultiSelect
      id="supplier-clients"
      v-model="selectedClientsComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.select', { property: t('supplier.clients').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="clients"
      class="w-full"
      option-label="name"
      option-value="id"
      data-testid="supplier-clients"
      display="chip"
      pt:list:data-testid="supplier-clients-list"
    />
    <label for="supplier-clients">
      {{ t(`supplier.clients`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useClient } from "@/api/client/ClientService";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { Client } from "@/models/client/Client";

const { t } = useI18n();
const { getAllClients } = useClient();

const props = defineProps<{
  selectedClientIds: string[];
}>();

const emit = defineEmits<{
  (e: "update:selectedClientIds", value: string[]): void;
}>();

const clients = ref<Client[]>([]);

const fetchClients = async () => {
  clients.value = await getAllClients();
};
onMounted(fetchClients);

const selectedClientsComputed = computed<string[]>({
  get: () => {
    return props.selectedClientIds ?? [];
  },
  set: (value) => {
    emit("update:selectedClientIds", value);
  },
});
</script>
