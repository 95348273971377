<template>
  <div class="mb-4">
    <FloatLabel variant="on">
      <InputText
        :id="`${addressType}-address-lines`"
        v-model="addressLinesComputed"
        v-tooltip.focus.bottom="{
          value: t('common.address.address-lines.placeholder'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        type="text"
        class="w-full"
        :invalid="val.addressLines.$error"
        :data-testid="`${addressType}-address-lines`"
        @change="onChangeComplete"
      />

      <label>{{ t("common.address.address-lines.label") }}</label>
    </FloatLabel>
    <small v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`" class="p-error">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>

  <div class="mb-4">
    <FloatLabel variant="on">
      <Select
        :id="`${addressType}-address-country`"
        v-model="selectedCountryComputed"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('common.address.country').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :options="countryOptions"
        option-label="name"
        option-value="iso"
        class="inputfield w-full"
        :invalid="val.countryIso.$error"
        :data-testid="`${addressType}-address-country`"
        :pt:list:data-testid="`${addressType}-address-country-list`"
        @change="onChangeComplete"
      />
      <label :for="`${addressType}-address-country`">{{ t("common.address.country") }}</label>
    </FloatLabel>
    <small v-if="val.countryIso.$error" :id="`${addressType}-address-country-error`" class="p-error">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-4">
      <FloatLabel variant="on">
        <InputText
          :id="`${addressType}-address-postal-code`"
          v-model="addressComputed.postalCode"
          v-debounce:100="formatPostCode"
          v-tooltip.focus.bottom="{
            value: t('placeholder.type', { property: t('common.address.postal-code').toLowerCase() }),
            showDelay: 1000,
            hideDelay: 300,
          }"
          :data-testid="`${addressType}-address-postal-code`"
          maxlength="10"
          type="text"
          :invalid="val.postalCode.$error"
          class="w-full"
          @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
        />
        <label>{{ t("common.address.postal-code") }}</label>
      </FloatLabel>

      <small v-if="val.postalCode.$error" :id="`${addressType}-address-postal-code-error`" class="p-error">{{
        val.postalCode.$errors[0].$message
      }}</small>
    </div>
    <div class="col-span-8">
      <FloatLabel variant="on">
        <InputText
          :id="`${addressType}-address-city`"
          v-model="addressComputed.city"
          v-tooltip.focus.bottom="{
            value: t('placeholder.type', { property: t('common.address.city').toLowerCase() }),
            showDelay: 1000,
          }"
          :data-testid="`${addressType}-address-city`"
          type="text"
          class="w-full"
          :invalid="val.city.$error"
          @change="onChangeComplete"
        />

        <label>{{ t("common.address.city") }}</label>
      </FloatLabel>

      <small v-if="val.city.$error" :id="`${addressType}-address-city-error`" class="p-error">{{
        val.city.$errors[0].$message
      }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Country } from "@/models/country/Country";
import { SupplierAddress } from "@/repositories/supplier/model/SupplierAddress";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/api/country/CountryService";

const { t } = useI18n();
const { getCity } = useCountry();

const props = defineProps<{
  address: SupplierAddress;
  countryOptions: Country[];
  addressType: string;
  selectedCountry: string;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: SupplierAddress): void;
  (e: "autoSelectCountry", value: string): void;
}>();

const addressComputed = computed<SupplierAddress>(() => {
  return props.address ? cloneDeep(props.address) : new SupplierAddress();
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines.join("\n");
  },
  set: (val) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    if (props.selectedCountry) {
      emit("autoSelectCountry", props.selectedCountry);
    }
    return addressComputed.value.countryIso;
  },
  set: (value: string) => {
    addressComputed.value.countryIso = value;
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }
  if (addressComputed.value.countryIso == "NO") {
    const response = await getCity(code);
    addressComputed.value.city = response[0].city;
  }
  onChangeComplete();
};

const formatPostCode = async (code: string) => {
  if (addressComputed.value.countryIso == "SE") {
    addressComputed.value.postalCode = code.replace(/(.{3})/g, "$1 ").trim();

    return addressComputed.value.postalCode;
  }
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines.error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);
</script>

<style scoped lang="scss">
.c-faded-label {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
