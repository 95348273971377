<template>
  <SupplierHeader
    :edit-mode="true"
    :has-updates="hasUpdates"
    :collapsed-panels="panelCollapsed"
    @on-cancel="routeToSupplierList"
    @on-save="onUpdateSupplier"
    @on-delete="onConfirmDelete"
    @on-toggle-all-panels="onToggleAllPanels()"
  />

  <Message v-if="loadFailed" closable severity="error" class="mx-5 my-3" pt:text:class="w-full">
    <div class="w-full">
      {{ t("common.loading-failed") }} {{ errorReason }}
      <Button
        :label="t('common.reload')"
        severity="danger"
        variant="text"
        icon="pi pi-refresh"
        icon-pos="right"
        pt:label:class="underline"
        class="ml-2"
        @click="reload"
      ></Button>
    </div>
  </Message>
  <div data-testid="edit-product" class="c-page-content mt-2 ml-4 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <SupplierNumber
                  ref="supplierNumberRef"
                  v-model:supplier-number="supplier.supplierNumber"
                  :is-edit="true"
                  :focus-input="true"
                />
              </div>

              <div class="mb-4">
                <Country v-model:country-iso="supplier.countryIso" :country-options="countryOptions" />
              </div>

              <div class="mb-4 md:mb-0">
                <DocumentLanguage v-model:document-language="supplier.documentLanguage" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Name v-model:name="supplier.name" />
              </div>

              <div class="mb-4">
                <Email v-model:email="supplier.email" />
              </div>
              <div class="mb-4 md:mb-0">
                <Clients v-model:selected-client-ids="supplier.clientIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-4">
            <div class="col-span-12">
              <div class="mb-4">
                <BusinessNumber v-model:business-number="supplier.businessNumber" />
              </div>

              <div class="mb-4">
                <PhoneNumber v-model:phone-number="supplier.phoneNumber" />
              </div>

              <PurchasePermit v-model:purchase-permit="supplier.purchasePermit" />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      id="addressPanelCollapsed"
      :header="t('supplier.addresses.label')"
      toggleable
      class="my-4 rounded-2xl"
      :collapsed="panelCollapsedAddress"
      data-testid="addresses-panel"
      :pt:header:on-click="() => (panelCollapsedAddress = !panelCollapsedAddress)"
    >
      <div class="grid grid-cols-12 gap-4 mb-4 mx-4">
        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4" data-testid="company-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.company-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.companyAddress"
            :country-options="countryOptions"
            :address-type="addressTypeCompany"
            @set-address="setCompanyAddress"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4 mx-4" data-testid="return-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.return-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.returnAddress"
            :country-options="countryOptions"
            :address-type="addressTypeReturn"
            @set-address="setReturnAddress"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4 mx-4" data-testid="invoice-address">
          <label class="inline-block mb-3 font-semibold">
            {{ t("supplier.addresses.invoice-address") }}
          </label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.invoiceAddress"
            :country-options="countryOptions"
            :address-type="addressTypeInvoice"
            @set-address="setInvoiceAddress"
          />
        </div>
      </div>
    </Panel>

    <Panel
      id="contactsPanelCollapsed"
      :header="t('supplier.contacts.header')"
      toggleable
      :collapsed="panelCollapsedContact"
      class="my-4 rounded-2xl"
      data-testid="contacts-panel"
      :pt:header:on-click="() => (panelCollapsedContact = !panelCollapsedContact)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <ContactList v-model:contacts="supplier.contacts" />
        </div>
      </div>
    </Panel>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <div class="col-span-12 lg:col-span-8 mb-6 rounded-2xl">
        <Panel
          id="conditionsAndFreightPanelCollapsed"
          :header="t('supplier.condition-and-freight')"
          toggleable
          :collapsed="panelCollapsedConditionFreight"
          data-testid="conditions-and-freight-panel"
          class="rounded-2xl"
          :pt:header:on-click="() => (panelCollapsedConditionFreight = !panelCollapsedConditionFreight)"
        >
          <div class="grid grid-cols-12 gap-4 lg:min-h-[15rem] mx-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Currency v-model:currency="supplier.currency" />
              </div>
              <div class="mb-4">
                <DefaultPaymentTerm v-model:payment-term-id="supplier.defaultPaymentTermId" />
              </div>
              <div class="mb-4">
                <Incoterms v-model:incoterms="supplier.incoterms" />
              </div>
              <div class="mb-4">
                <EstimatedDeliveryDays v-model:estimated-delivery-days="supplier.estimatedDeliveryDays" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <FreightMethod v-model:freight-method-id="supplier.freightMethodId" />
              </div>
              <div class="mb-4">
                <FixedShipping v-model:fixed-shipping="supplier.fixedShipping" />
              </div>
              <div class="mb-4">
                <FreeShipping v-model:free-freight="supplier.freeShippingFrom" />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          id="conditionsDocumentsPanelCollapsed"
          :header="t('supplier.documents.header')"
          toggleable
          :collapsed="panelCollapseDocuments"
          data-testid="documents-panel"
          class="rounded-2xl"
          :pt:header:on-click="() => (panelCollapseDocuments = !panelCollapseDocuments)"
        >
          <div class="grid grid-cols-12 gap-4 lg:min-h-[15rem] mx-4">
            <div class="col-span-12">
              <Documents v-model:documents="supplier.documents" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { Supplier } from "@/repositories/supplier/model/Supplier";
import { type Country as CountryModel } from "@/models/country/Country";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useCountry } from "@/api/country/CountryService";
import { supplierAddressFunctions } from "../SupplierAddressFunctions";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const toast = useCumulusToast(useToast());
const { updateSupplier, deleteSupplier, getSupplier } = useSupplier();
const { getAllCountries } = useCountry();

const loadFailed = ref(false);
const errorReason = ref("");
const isLoading = ref(true);
const countryOptions = ref<CountryModel[]>([]);
const addressTypeInvoice = ref("invoice");
const addressTypeReturn = ref("return");
const addressTypeCompany = ref("company");

const supplier = ref<Supplier>(new Supplier());
const initialSupplier = ref<Supplier | null>(null);
const supplierNumberRef = ref();
const confirmedDiscard = ref(false);
const { setCompanyAddress, setReturnAddress, setInvoiceAddress } = supplierAddressFunctions(supplier);

const val = useValidate();

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", supplier.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const onUpdateSupplier = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await updateSupplier(supplier.value);
  initialSupplier.value = cloneDeep(supplier.value);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.update-success.summary"),
    detail: t("supplier.toast.update-success.detail", { name: supplier.value.name }),
    closable: true,
  });

  routeToSupplierList();
};

const fetchSupplierById = async () => {
  const id = route.params.id as string;

  try {
    supplier.value = await getSupplier(id);
    initialSupplier.value = cloneDeep(supplier.value);
  } catch {
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const panelCollapsedAddress = ref(false);
const panelCollapsedContact = ref(false);
const panelCollapsedConditionFreight = ref(false);
const panelCollapseDocuments = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedAddress.value &&
    panelCollapsedContact.value &&
    panelCollapsedConditionFreight.value &&
    panelCollapseDocuments.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAddress.value = newState;
  panelCollapsedContact.value = newState;
  panelCollapsedConditionFreight.value = newState;
  panelCollapseDocuments.value = newState;
};

onMounted(() => {
  Promise.all([fetchSupplierById(), fetchCountryData()]);
  focusInput();
});

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("supplier.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      onDeleteSupplier();
    },
  });
};
const onDeleteSupplier = async () => {
  await deleteSupplier(supplier.value.id);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.delete-success.summary"),
    closable: true,
  });

  routeToSupplierList();
};

const hasUpdates = computed(() => {
  return !isEqual(supplier.value, initialSupplier.value);
});

const focusInput = async () => {
  await nextTick();
  supplierNumberRef.value.focus();
};

const reload = () => {
  window.location.reload();
};

useUnsavedChanges(hasUpdates);
</script>

<style scoped lang="scss">
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}

.navigation-shortcut {
  height: auto;
  a:focus {
    outline: none;
    border: 2px solid #59b5e8;
  }
  ul {
    list-style-type: none;
    padding: 0.2rem;
    li {
      outline: none;
    }
  }
}
@media (max-width: 992px) {
  .navigation-shortcut {
    display: none;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev),
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next) {
  color: #fff;
  margin: 0.1rem;
}
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus) {
  box-shadow: none;
}

.c-header {
  color: var(--text-color);
  font-weight: bold;
  font-size: large;
}
</style>
