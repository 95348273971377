<template>
  <div class="card m-4">
    <div class="flex items-center gap-2 mb-4">
      <div class="flex-1 text-color font-semibold leading-6">
        <PrimeButton
          :label="t('supplier.new-supplier')"
          severity="secondary"
          variant="outlined"
          @click="router.push({ name: 'supplier-add' })"
          raised
        />
      </div>
      <div>
        <PrimeButton
          data-testid="btn-selected-icon"
          @click="openSortList"
          :class="{ 'c-toolbar-button-active': isShowedSorting }"
          severity="secondary"
          variant="outlined"
          raised
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.sorting'),
            showDelay: 1000,
            hideDelay: 100,
          }"
        >
          <i :class="selectedIcon"></i>
        </PrimeButton>
      </div>
      <div class="ml-2">
        <PrimeButton
          data-testid="c-refresh-button"
          @click="emit('refreshList')"
          severity="secondary"
          variant="outlined"
          raised
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.refresh'),
            showDelay: 1000,
            hideDelay: 300,
          }"
        >
          <i class="pi pi-refresh"></i>
        </PrimeButton>
      </div>
      <div class="ml-2">
        <PrimeButton
          data-testid="btn-column-chooser"
          @click="openColumnChooser"
          severity="secondary"
          :class="{ 'c-toolbar-button-active': isShowedChooser }"
          variant="outlined"
          raised
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.column-chooser'),
            showDelay: 1000,
            hideDelay: 300,
          }"
        >
          <i class="pi pi-list"></i>
        </PrimeButton>
      </div>
      <div class="ml-2">
        <PrimeButton
          data-testid="btn-filter"
          @click="emit('onToggleFilterSidebar')"
          :class="{ 'c-toolbar-button-active': props.visible }"
          severity="secondary"
          variant="outlined"
          raised
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.filter'),
            showDelay: 1000,
            hideDelay: 300,
          }"
        >
          <i class="pi pi-filter"></i>
        </PrimeButton>
      </div>
    </div>

    <DataTable
      ref="supplierSearchResult"
      :value="suppliers"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedSupplier"
      dataKey="id"
      :autoLayout="true"
      :lazy="true"
      :paginator="true"
      paginatorPosition="bottom"
      responsiveLayout="scroll"
      selectionMode="single"
      @row-dblclick="onRowDblClicked"
      @row-select="onRowSelected"
      :rowClass="addGlobalSearchClass"
      class="c-datatable"
      stripedRows
      :loading="loading"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      data-testid="supplier-table"
      :totalRecords="totalHits"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      @page="onPage"
      tabindex="0"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      :sortOrder="sortOrder"
      :sortField="sortField"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
      @sort="onSort"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="t(col.header)"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
            class: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === SupplierLinesColumns.RegisteredDate">
            <span>{{ d(data.registeredDate, "short") }}</span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.SupplierNumber">
            <span class="c-supplier-number-text" @click="onSupplierNumberClicked(data)">
              {{ data.supplierNumber }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Name">
            {{ data.name }}
            <i
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              @click.stop="copyName(data.name)"
              v-tooltip.right="{ value: t('common.copy') }"
              :data-testid="`name-copy-button-${data.id}`"
            />
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.PhoneNumber">
            {{ data.phoneNumber }}
            <i
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              @click.stop="copyPhoneNumber(data.phoneNumber)"
              v-tooltip.right="{ value: t('common.copy') }"
              :data-testid="`phone-number-copy-button-${data.id}`"
            ></i>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Email">
            <span>
              {{ data.email }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.AddressLines">
            {{ joinAddressLines(data.companyAddress.addressLines) }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.City">
            {{ data.companyAddress.city }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.CountryIso">
            {{ data.companyAddress.countryIso }}
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #empty>
        <div v-if="!loading">{{ t("supplier.search.empty-list") }}</div>
      </template>
      <template #loading>{{ t("supplier.search.loading-list") }}</template>
    </DataTable>
  </div>

  <Popover ref="op" data-testid="supplier-sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("supplier.search.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in supplierSearchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="onSelectedRow(column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <Popover ref="chooserRef" data-testid="supplier-column-list">
    <DataTable
      v-model:selection="selectedColumnsComputed"
      :value="filteredColumns"
      dataKey="field"
      tableStyle="min-width: 16rem"
      responsiveLayout="stack"
      breakpoint="991px"
      selectionMode="multiple"
      :metaKeySelection="false"
      :selectAll="selectAll"
      @select-all-change="onSelectAllChange"
      @row-select="onColumnSelected($event.data)"
      @row-unselect="onColumnUnselected($event.data)"
    >
      <div class="card flex justify-center">
        <div class="flex flex-col gap-4">
          <Column selectionMode="multiple" />
          <Column field="header">
            <template #body="{ data }">
              <span data-testid="column-value-row">{{ t(data.header) }}</span>
            </template>
          </Column>
        </div>
      </div>
    </DataTable>
    <div class="flex justify-end">
      <PrimeButton
        type="button"
        :label="t('common.reset')"
        class="w-full m-4"
        severity="danger"
        text
        data-testid="reset-column-button"
        @click="resetColumns"
        autoOptionFocus="false"
        :tabindex="-1"
      ></PrimeButton>
    </div>
  </Popover>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { SupplierSearch } from "@/repositories/search/model/SupplierSearch";
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { useAuth } from "@cumulus/event-bus";
import { SupplierLinesColumns } from "@/repositories/search/model/SupplierLinesColumns";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { getUser } = useAuth();
const { t, d } = useI18n();

const router = useRouter();
const route = useRoute();

const toast = useCumulusToast(useToast());
const supplierSearchResult = ref();
const selectedSupplier = ref<SupplierSearch>(new SupplierSearch());
const expandedRows = ref([]);

const props = defineProps<{
  suppliers: SupplierSearch[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
  visible: boolean;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sortOrder", value: number): void;
  (e: "refreshList"): void;
  (e: "onToggleFilterSidebar"): void;
}>();

const supplierSearchColumns: DataTableColumn[] = [
  { field: "registeredDate", header: "supplier.registered", class: "w-1rem", sortable: true },
  { field: "supplierNumber", header: "supplier.number.label", class: "w-12", sortable: true },
  { field: "name", header: "supplier.search.header-name", sortable: true, class: "c-supplier-name" },
  {
    field: "phoneNumber",
    header: "supplier.search.header-phone-number",
    sortable: true,
    class: "c-supplier-phone-number",
  },
  { field: "email", header: "supplier.email", sortable: true },
  {
    field: "companyAddress.addressLines",
    header: "supplier.addresses.label",
    sortable: true,
    class: "c-supplier-addresses",
  },
  {
    field: "companyAddress.city",
    header: "common.address.city",
    sortable: true,
    class: "c-supplier-city w-2rem",
  },
  {
    field: "companyAddress.countryIso",
    header: "common.address.country",
    sortable: true,
    class: "c-supplier-country w-1rem",
  },
];
const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("supplierSearch", supplierSearchColumns, null, (await getUser()).getEmployee().id);

const isShowedSorting = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isShowedChooser = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;

  selectedColumnsComputed.value = event.checked ? supplierSearchColumns : [];
};

watch([() => props.suppliers, () => props.loading], ([suppliers, loading]) => {
  if (loading === false && suppliers.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(supplierSearchColumns.find((column) => column.field === SupplierLinesColumns.Name));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);
    emit("update:sortField", defaultSortField.value.field);
    emit("update:page", 1);
  });
};

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = supplierSearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: SupplierSearch) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedSupplier.value?.id === data?.id },
  ];
};

const joinAddressLines = (address: string[]): string => {
  return address.join(", ");
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchSupplier(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openSearchSupplier(event.data.id);
};

const copyName = (name: string) => {
  navigator.clipboard.writeText(name);
};

const copyPhoneNumber = (phoneNumber: string) => {
  navigator.clipboard.writeText(phoneNumber);
};

const openSearchSupplier = (supplierId: string) => {
  supplierId != null && supplierId !== emptyUuid
    ? router.push({ name: "supplier-edit", params: { id: supplierId } })
    : toast.add({
        severity: "warn",
        summary: t("supplier.toast.open-supplier-failed"),
        detail: t("supplier.search-header.no-supplier-selected"),
      });
};

const focusSearchResult = (id = "") => {
  if (props.suppliers.length > 0 && supplierSearchResult.value) {
    const element = id === "" ? null : supplierSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }

    supplierSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onSupplierNumberClicked = (supplier: SupplierSearch) => {
  selectedSupplier.value = supplier;
  openSearchSupplier(supplier.id);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>

<style scoped lang="scss">
.c-supplier-number-text:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row .pi.pi-copy.c-copy-column-text-btn {
  font-size: 1.25rem;
  visibility: hidden;
}

tr.p-datatable-selectable-row td.c-supplier-phone-number:hover .c-copy-column-text-btn {
  visibility: visible;
}

tr.p-datatable-selectable-row td.c-supplier-name:hover .c-copy-column-text-btn {
  visibility: visible;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
