<template>
  <teleport v-if="isMounted" to="#teleport-product-toolbar">
    <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
      <Button
        id="btn-save"
        class="c-circular-button mr-4"
        data-testid="btn-save"
        :disabled="!props.hasUpdates"
        @click="emit('onSave')"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ editMode ? t("common.save") : t("common.create") }}</span>
      </Button>
    </div>

    <Button v-if="editMode" class="c-circular-button mr-4" data-testid="btn-delete" @click="(e) => emit('onDelete', e)">
      <i class="pi pi-trash c-delete-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.delete") }}</span>
    </Button>

    <Button class="c-circular-button" @click="onToggleAllPanels">
      <i class="pi pi-clone c-default-button c-circular-icon" />
      <span class="px-3">{{
        props.collapsedPanels ? t("supplier.panel.open-panels") : t("supplier.panel.close-panels")
      }}</span>
    </Button>
  </teleport>
</template>

<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{
  hasUpdates: boolean;
  editMode: boolean;
  collapsedPanels: boolean;
}>();

const emit = defineEmits<{
  (e: "onSave"): void;
  (e: "onDelete", event: Event): void;
  (e: "onCancel"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
}>();

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>
<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
