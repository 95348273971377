<template>
  <FloatLabel variant="on">
    <InputText
      v-model="selectedPhoneNumber"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.phone-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      type="tel"
      data-testid="supplier-phone-number"
      class="w-full"
      :invalid="val.phoneNumber.$error"
      aria-describedby="supplier-phone-number-help"
    />

    <label>{{ t("supplier.phone-number") }}</label>
  </FloatLabel>
  <small v-if="val.phoneNumber.$error" class="p-error" data-testid="phone-number-error">{{
    val.phoneNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { computed } from "vue";
import { required, minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  phoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string | null): void;
}>();

const selectedPhoneNumber = computed<string>({
  get: () => {
    return props.phoneNumber ?? "";
  },
  set: (value) => {
    emit("update:phoneNumber", value);
  },
});

const { t } = useI18n();

const rules = {
  phoneNumber: {
    required,
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
  },
};

const val = useValidate(rules, props);
</script>
