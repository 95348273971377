<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        rounded
        size="small"
        variant="text"
        data-testid="btn-cancel"
        @click="routeToSupplierList"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800 dark:text-white">{{ t("supplier.new-supplier") }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <Button id="btn-commit" class="c-circular-button mr-4" data-testid="btn-commit" @click="addSupplier">
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.create") }}</span>
      </Button>

      <Button class="c-circular-button" @click="onToggleAllPanels">
        <i class="pi pi-clone c-default-button c-circular-icon"></i>
        <span class="px-3">{{
          panelCollapsed ? t("supplier.panel.open-panels") : t("supplier.panel.close-panels")
        }}</span>
      </Button>
    </div>
  </div>

  <div class="mt-2 pr-1" data-testid="supplier-add">
    <div class="grid grid-cols-12 mt-4 mx-4">
      <Card class="col-span-12 lg:col-span-8 mr-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <SupplierNumber
                  v-model:supplier-number="supplier.supplierNumber"
                  :is-edit="false"
                  :focus-input="true"
                />
              </div>

              <div class="mb-4">
                <Country v-model:country-iso="supplier.countryIso" :country-options="countryOptions" />
              </div>

              <div class="mb-4 md:mb-0">
                <DocumentLanguage v-model:document-language="supplier.documentLanguage" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Name ref="supplierNameRef" v-model:name="supplier.name" />
              </div>

              <div class="mb-4">
                <Email v-model:email="supplier.email" />
              </div>
              <div class="mb-4 md:mb-0">
                <Clients v-model:selected-client-ids="supplier.clientIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-4">
            <div class="col-span-12">
              <div class="mb-4">
                <BusinessNumber v-model:business-number="supplier.businessNumber" />
              </div>

              <div>
                <PhoneNumber v-model:phone-number="supplier.phoneNumber" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      id="addressPanelCollapsed"
      :header="t('supplier.addresses.label')"
      toggleable
      :collapsed="panelCollapsedAddress"
      class="my-4 rounded-2xl mx-4"
      :pt:header:on-click="() => (panelCollapsedAddress = !panelCollapsedAddress)"
    >
      <div class="grid grid-cols-12 gap-4 mb-4 mx-4">
        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4" data-testid="company-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.company-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.companyAddress"
            :country-options="countryOptions"
            :address-type="addressTypeCompany"
            @set-address="setCompanyAddress"
            @auto-select-country="autoSelectedCountry"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:pr-4" data-testid="return-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.return-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.returnAddress"
            :country-options="countryOptions"
            :address-type="addressTypeReturn"
            @set-address="setReturnAddress"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4" data-testid="invoice-address">
          <label class="inline-block mb-3 font-semibold">
            {{ t("supplier.addresses.invoice-address") }}
          </label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.invoiceAddress"
            :country-options="countryOptions"
            :address-type="addressTypeInvoice"
            @set-address="setInvoiceAddress"
          />
        </div>
      </div>
    </Panel>

    <Panel
      id="contactsPanelCollapsed"
      :header="t('supplier.contacts.header')"
      toggleable
      :collapsed="panelCollapsedContact"
      class="my-4 rounded-2xl mx-4"
      :pt:header:on-click="() => (panelCollapsedContact = !panelCollapsedContact)"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <ContactList v-model:contacts="supplier.contacts" />
        </div>
      </div>
    </Panel>

    <div class="grid grid-cols-12 gap-4 mb-4 mx-4">
      <div class="col-span-12 lg:col-span-8">
        <Panel
          id="conditionsAndFreightPanelCollapsed"
          :header="t('supplier.condition-and-freight')"
          toggleable
          class="rounded-2xl"
          :collapsed="panelCollapsedConditionFreight"
          :pt:header:on-click="() => (panelCollapsedConditionFreight = !panelCollapsedConditionFreight)"
        >
          <div class="grid grid-cols-12 gap-4 lg:min-h-[15rem] mx-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Currency v-model:currency="supplier.currency" />
              </div>
              <div class="mb-4">
                <DefaultPaymentTerm v-model:payment-term-id="supplier.defaultPaymentTermId" />
              </div>
              <div class="mb-4">
                <Incoterms v-model:incoterms="supplier.incoterms" />
              </div>
              <div class="mb-4">
                <EstimatedDeliveryDays v-model:estimated-delivery-days="supplier.estimatedDeliveryDays" />
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <FreightMethod v-model:freight-method-id="supplier.freightMethodId" />
              </div>
              <div class="mb-4">
                <FixedShipping v-model:fixed-shipping="supplier.fixedShipping" />
              </div>
              <div class="mb-4">
                <FreeShipping v-model:free-freight="supplier.freeShippingFrom" />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          id="conditionsDocumentsPanelCollapsed"
          :header="t('supplier.documents.header')"
          toggleable
          :collapsed="panelCollapseDocuments"
          data-testid="documents-panel"
          class="rounded-2xl"
          :pt:header:on-click="() => (panelCollapseDocuments = !panelCollapseDocuments)"
        >
          <div class="grid grid-cols-12 gap-4 lg:min-h-[15rem] mx-4">
            <div class="col-span-12">
              <Documents v-model:documents="supplier.documents" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { Supplier } from "@/repositories/supplier/model/Supplier";
import type { Country as CountryModel } from "@/models/country/Country";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { supplierAddressFunctions } from "../SupplierAddressFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useCountry } from "@/api/country/CountryService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useAuth } from "@cumulus/event-bus";
import { useClient } from "@/api/client/ClientService";
import { useCurrency } from "@/api/currency/CurrencyService";

const { t } = useI18n();
const router = useRouter();
const toast = useCumulusToast(useToast());
const { createSupplier } = useSupplier();
const { getAllCountries } = useCountry();
const countryOptions = ref<CountryModel[]>([]);
const addressTypeInvoice = ref("invoice");
const addressTypeReturn = ref("return");
const addressTypeCompany = ref("company");

const supplier = ref<Supplier>(new Supplier());

const initialSupplier = ref<Supplier>(new Supplier());
const { getCurrency } = useCurrency();
const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

onMounted(async () => {
  const defaultContact = new SupplierContact();
  defaultContact.isDefaultContact = true;
  supplier.value.contacts.push(defaultContact);

  const client = await getClient((await getAuthHeaders()).clientId);
  supplier.value.countryIso = client.countryIso;
  supplier.value.currency = await getCurrency(client.currencyIso);
  supplier.value.documentLanguage = client.countryIso;
  initialSupplier.value = cloneDeep(supplier.value);
});

const { setCompanyAddress, setReturnAddress, setInvoiceAddress } = supplierAddressFunctions(supplier);

//Nested validation
const val = useValidate();

const supplierNameRef = ref();
const confirmedDiscard = ref(false);

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const addSupplier = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await createSupplier(supplier.value);
  initialSupplier.value = cloneDeep(supplier.value);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.add-success.summary"),
    detail: t("supplier.toast.add-success.detail", { name: supplier.value.name }),
    closable: true,
  });

  routeToSupplierList();
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const autoSelectedCountry = (countryIso: string) => {
  if (supplier.value.companyAddress.countryIso === "") {
    supplier.value.companyAddress.countryIso = countryIso;
    supplier.value.invoiceAddress.countryIso = countryIso;
    supplier.value.returnAddress.countryIso = countryIso;
  }
};

const panelCollapsedAddress = ref(false);
const panelCollapsedContact = ref(false);
const panelCollapsedConditionFreight = ref(false);
const panelCollapseDocuments = ref(false);

const panelCollapsed = computed(() => {
  return (
    panelCollapsedAddress.value &&
    panelCollapsedContact.value &&
    panelCollapsedConditionFreight.value &&
    panelCollapseDocuments.value
  );
});

const onToggleAllPanels = () => {
  const newState = !panelCollapsed.value;
  panelCollapsedAddress.value = newState;
  panelCollapsedContact.value = newState;
  panelCollapsedConditionFreight.value = newState;
  panelCollapseDocuments.value = newState;
};

const hasUpdates = computed(() => {
  return !isEqual(supplier.value, initialSupplier.value);
});

onMounted(async () => {
  focusInput();
  fetchCountryData();
});

const focusInput = async () => {
  await nextTick();
  supplierNameRef.value.focus();
};

useUnsavedChanges(hasUpdates);
</script>

<style scoped lang="scss">
.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
