<template>
  <FloatLabel variant="on">
    <InputText
      :id="`contact-last-name-${props.rowNumber}`"
      v-model="lastNameComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.contacts.last-name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :data-testid="`contact-last-name-${props.rowNumber}`"
      class="w-full"
      :invalid="val.lastName.$error"
    />
    <label for="`contact-last-name-${index}`">{{ t("supplier.contacts.last-name") }}</label>
  </FloatLabel>
  <small v-if="val.lastName.$error" class="p-error" data-testid="contact-last-name-error">
    {{ val.lastName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { computed } from "vue";
import { minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";

const props = defineProps<{
  lastName: string;
  contact: SupplierContact;
  rowNumber: number;
}>();

const emit = defineEmits<{
  (e: "update:lastName", value: string): void;
}>();

const lastNameComputed = computed<string>({
  get: () => {
    return props.lastName ?? "";
  },
  set: (value) => {
    emit("update:lastName", value);
  },
});

const { t } = useI18n();

const validateContacts = () => {
  if (props.contact.lastName !== "") {
    //Valid
    return true;
  }

  if (
    props.contact.firstName === "" &&
    props.contact.lastName === "" &&
    props.contact.email === "" &&
    props.contact.phoneNumber === ""
    //Valid
  ) {
    return true;
  }

  if (props.contact.firstName !== "" || props.contact.lastName !== "") {
    if (props.contact.phoneNumber !== "" || props.contact.email !== "") {
      //Valid
      return true;
    }
  }

  //Not valid
  return false;
};

const rules = {
  lastName: {
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(2)),
    required: helpers.withMessage(() => t("validations.lastname-required"), validateContacts),
  },
};

const val = useValidate(rules, props);
</script>
