<template>
  <FloatLabel variant="on">
    <Select
      id="supplier-incoterms"
      v-model="selectedIncoterms"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('supplier.incoterms').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="incotermsOptions"
      option-label="code"
      data-testid="supplier-incoterms"
      class="w-full"
      pt:list:data-testid="supplier-incoterms-list"
    />

    <label for="supplier-document-language">
      {{ t("supplier.incoterms") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { incotermsRepository } from "@/repositories/incoterms/IncotermsRepository";
import { computed, onMounted, ref } from "vue";
import { Incoterms } from "@/repositories/supplier/model/Incoterms";

const props = defineProps<{
  incoterms: Incoterms | null;
}>();

const emit = defineEmits<{
  (e: "update:incoterms", value: Incoterms | null): void;
}>();

const { t } = useI18n();
const incotermsOptions = ref<Incoterms[]>([]);

const selectedIncoterms = computed<Incoterms | null>({
  get: () => {
    let incoterms: Incoterms | null = null;
    incotermsOptions.value.find((i) => {
      if (i.code === props.incoterms?.code) {
        incoterms = i;
      }
    });
    return incoterms;
  },
  set: (value) => {
    emit("update:incoterms", value);
  },
});

const fetchIncoterms = () => {
  incotermsRepository.getAll().then((response) => {
    response.map((c) => {
      incotermsOptions.value.push({
        code: c.code,
        description: c.description,
      } as Incoterms);
    });
  });
};
onMounted(fetchIncoterms);
</script>
