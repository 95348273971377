<template>
  <FloatLabel variant="on">
    <Select
      id="supplier-document-language"
      v-model="selectedDocumentLanguage"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('supplier.document-language').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="documentLanguages"
      option-label="name"
      option-value="id"
      data-testid="supplier-document-language"
      class="w-full"
      :invalid="val.documentLanguage.$error"
      pt:list:data-testid="supplier-document-language-list"
    >
      <template #option="slotProps">
        <div class="flex items-center">
          <img
            src="@/assets/flag_placeholder.png"
            :class="'flag flag-' + slotProps.option.id.toLowerCase()"
            width="16"
          />
          <div class="ml-4">{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Select>
    <label for="supplier-document-language">
      {{ t(`supplier.document-language`) }}
    </label>
  </FloatLabel>
  <small v-if="val.documentLanguage.$error" data-testid="supplier-document-language-error" class="p-error">{{
    val.documentLanguage.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { documentLanguageRepository } from "@/repositories/document-language/DocumentLanguageRepository";
import type { DocumentLanguage } from "@/repositories/document-language/model/DocumentLanguage";
import { useVuelidate } from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  documentLanguage: string;
}>();

const emit = defineEmits<{
  (e: "update:documentLanguage", value: string): void;
}>();

const selectedDocumentLanguage = computed<string>({
  get: () => {
    return props.documentLanguage ?? "";
  },
  set: (value) => {
    emit("update:documentLanguage", value);
  },
});

const documentLanguages = ref<DocumentLanguage[]>([]);
const fetchDocumentLanguages = async () => {
  const response = await documentLanguageRepository.getAll();
  documentLanguages.value = response.map((languageId: string): DocumentLanguage => {
    return {
      id: languageId,
      name: t(`common.document-language.${languageId}`),
    };
  });
};
onMounted(fetchDocumentLanguages);

const rules = {
  documentLanguage: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>

<style scoped lang="scss">
.p-float-label .p-select {
  box-shadow: none;
}
</style>
