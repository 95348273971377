<template>
  <div class="mt-8">
    <DataTable :value="products" tableStyle="min-width: 50rem">
      <template #header>
        <div class="flex flex-wrap items-center justify-between gap-2">
          <div></div>
          <div>
            <div>
              <PrimeButton data-testid="btn-selected-icon" severity="secondary" variant="outlined" class="mr-2">
                <i :class="selectedIcon"></i>
              </PrimeButton>
              <PrimeButton data-testid="c-refresh-button" severity="secondary" variant="outlined" class="mr-2">
                <i class="pi pi-refresh"></i>
              </PrimeButton>
              <PrimeButton data-testid="btn-column-chooser" severity="secondary" variant="outlined" class="mr-2">
                <i class="pi pi-list"></i>
              </PrimeButton>
              <PrimeButton data-testid="btn-filter" severity="secondary" variant="outlined">
                <i class="pi pi-filter"></i>
              </PrimeButton>
            </div>
          </div>
        </div>
      </template>
      <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"></Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const products = [
  {
    id: "1000",
    code: "f230fh0g3",
    name: "Bamboo Watch",
    description: "Product Description",
    image: "bamboo-watch.jpg",
    price: 65,
    category: "Accessories",
    quantity: 24,
    inventoryStatus: "INSTOCK",
    rating: 5,
  },
  {
    id: "1001",
    code: "nvklal433",
    name: "Black Watch",
    description: "Product Description",
    image: "black-watch.jpg",
    price: 72,
    category: "Accessories",
    quantity: 61,
    inventoryStatus: "INSTOCK",
    rating: 4,
  },
  {
    id: "1002",
    code: "zz21cz3c1",
    name: "Blue Band",
    description: "Product Description",
    image: "blue-band.jpg",
    price: 79,
    category: "Fitness",
    quantity: 2,
    inventoryStatus: "LOWSTOCK",
    rating: 3,
  },
  {
    id: "1003",
    code: "244wgerg2",
    name: "Blue T-Shirt",
    description: "Product Description",
    image: "blue-t-shirt.jpg",
    price: 29,
    category: "Clothing",
    quantity: 25,
    inventoryStatus: "INSTOCK",
    rating: 5,
  },
  {
    id: "1004",
    code: "h456wer53",
    name: "Bracelet",
    description: "Product Description",
    image: "bracelet.jpg",
    price: 15,
    category: "Accessories",
    quantity: 73,
    inventoryStatus: "INSTOCK",
    rating: 4,
  },
  {
    id: "1005",
    code: "av2231fwg",
    name: "Brown Purse",
    description: "Product Description",
    image: "brown-purse.jpg",
    price: 120,
    category: "Accessories",
    quantity: 0,
    inventoryStatus: "OUTOFSTOCK",
    rating: 4,
  },
];

const columns = [
  { field: "code", header: "Code" },
  { field: "name", header: "Name" },
  { field: "category", header: "Category" },
  { field: "quantity", header: "Quantity" },
];

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: -1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: 1 },
];

const currentIconIndex = ref(0);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});
</script>
