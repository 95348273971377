<template>
  <div class="c-toolbar-wrapper hidden">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button ml-3" @click="onToggleAllPanels">
          <i class="pi pi-clone c-default-button c-circular-icon" />
          <span class="px-3">{{
            props.collapsedPanels ? t("supplier.panel.open-panels") : t("supplier.panel.close-panels")
          }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('dialogCancelBtnClicked')"
    @discardClicked="emit('dialogDiscardBtnClicked')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>

  <!-- Css hack for Prime Dialog -->
  <PrimeDialog />
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
  editMode: boolean;
  collapsedPanels: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
  (e: "dialogDiscardBtnClicked"): void;
  (e: "dialogCancelBtnClicked"): void;
  (e: "onToggleAllPanels", currentValue: boolean): void;
}>();

const onToggleAllPanels = () => {
  emit("onToggleAllPanels", props.collapsedPanels);
};
</script>

<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
