<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 my-4 ml-4">
      <div class="col-span-12">
        <Card>
          <template #content>
            <SupplierList
              :suppliers="suppliers"
              :loading="loading"
              :total-hits="totalHits"
              :page="page"
              :page-size="pageSize"
              :sort-field="sortField"
              :sort-order="sortOrder"
              @update:sort-order="onUpdateSortOrder"
              @update:sort-field="onUpdateSortField"
              @update:page="onUpdatePage"
              @update:page-size="onUpdatePageSize"
              @refresh-list="onRefreshList"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { SupplierSearchRequest } from "@/repositories/search/model/SupplierSearchRequest";
import SupplierList from "../components/SupplierList.vue";
import { SupplierSearch } from "@/repositories/search/model/SupplierSearch";
import { useSearch } from "@/repositories/search/SearchService";
import { SearchFilters } from "@/repositories/search/model//SearchFilters";
const { supplierSearch } = useSearch();
const route = useRoute();

const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const query = ref("");
const loading = ref(false);
const suppliers = ref<SupplierSearch[]>([]);
const filters = ref<SearchFilters>(new SearchFilters());
const sortField = ref("name");
const sortOrder = ref(1);

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  },
);

onMounted(async () => {
  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});

const search = async () => {
  try {
    const request = new SupplierSearchRequest(query.value);

    request.page = page.value;
    request.pageSize = pageSize.value;
    request.filters = filters.value;

    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    const response = await supplierSearch(request);
    suppliers.value = response.suppliers;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};
</script>
