<template>
  <div class="card m-4">
    <div class="flex items-center gap-2 mb-4 border-b pb-2">
      <div class="flex-1 text-color font-semibold leading-6">
        <Button
          class="mr-8 text-sm"
          variant="text"
          size="small"
          data-testid="btn-add"
          @click="router.push({ name: 'supplier-add' })"
        >
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("supplier.new-supplier") }}</span>
        </Button>
      </div>
      <div>
        <Button
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.sorting'),
            showDelay: 1000,
            hideDelay: 100,
          }"
          data-testid="btn-selected-icon"
          :class="{ 'c-toolbar-button-active': isShowedSorting }"
          severity="secondary"
          text
          @click="openSortList"
        >
          <i :class="selectedIcon"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.refresh'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="c-refresh-button"
          severity="secondary"
          text
          @click="emit('refreshList')"
        >
          <i class="pi pi-refresh"></i>
        </Button>
      </div>
      <div class="ml-2">
        <Button
          v-tooltip.bottom="{
            value: t('supplier.search.toolbar.column-chooser'),
            showDelay: 1000,
            hideDelay: 300,
          }"
          data-testid="btn-column-chooser"
          severity="secondary"
          :class="{ 'c-toolbar-button-active': isShowedChooser }"
          text
          @click="openColumnChooser"
        >
          <i class="pi pi-list"></i>
        </Button>
      </div>
    </div>

    <DataTable
      ref="supplierSearchResult"
      :key="renderKey as unknown as number"
      v-model:expanded-rows="expandedRows"
      v-model:selection="selectedSupplier"
      :value="suppliers"
      data-key="id"
      :auto-layout="true"
      :lazy="true"
      :paginator="true"
      paginator-position="bottom"
      responsive-layout="scroll"
      selection-mode="single"
      :row-class="addGlobalSearchClass"
      class="c-datatable"
      striped-rows
      :loading="loading"
      :rows="pageSize"
      :rows-per-page-options="[50, 100]"
      data-testid="supplier-table"
      :total-records="totalHits"
      :current-page-report-template="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      tabindex="0"
      scrollable
      scroll-height="75vh"
      :resizable-columns="true"
      column-resize-mode="fit"
      :sort-order="sortOrder"
      :sort-field="sortField"
      :reorderable-columns="true"
      @row-dblclick="onRowDblClicked"
      @row-select="onRowSelected"
      @page="onPage"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
      @sort="onSort"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="t(col.header)"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
            class: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === SupplierLinesColumns.RegisteredDate">
            <span>{{ d(data.registeredDate, "short") }}</span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.SupplierNumber">
            <span class="c-supplier-number-text" @click="onSupplierNumberClicked(data)">
              {{ data.supplierNumber }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Name">
            {{ data.name }}
            <i
              v-tooltip.right="{ value: t('common.copy') }"
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              :data-testid="`name-copy-button-${data.id}`"
              @click.stop="copyName(data.name)"
            />
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.PhoneNumber">
            {{ data.phoneNumber }}
            <i
              v-tooltip.right="{ value: t('common.copy') }"
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              :data-testid="`phone-number-copy-button-${data.id}`"
              @click.stop="copyPhoneNumber(data.phoneNumber)"
            ></i>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Email">
            <span>
              {{ data.email }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.AddressLines">
            {{ joinAddressLines(data.companyAddress.addressLines) }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.City">
            {{ data.companyAddress.city }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.CountryIso">
            {{ data.companyAddress.countryIso }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.PurchasePermit">
            {{ t(`supplier.status.${data.purchasePermit.toLowerCase()}`) }}
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>

      <template #empty>
        <div v-if="!loading">{{ t("supplier.search.empty-list") }}</div>
      </template>
      <template #loading>{{ t("supplier.search.loading-list") }}</template>
    </DataTable>
  </div>

  <PopOverSorting
    ref="op"
    v-model:selected-icon="selectedIcon"
    :search-columns="filteredColumns"
    :default-sort-field="defaultSortField"
    @on-selected-row="onSelectedRow"
  />

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selected-columns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @reset-columns="resetColumns"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { SupplierSearch } from "@/repositories/search/model/SupplierSearch";
import type {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/components";
import { SupplierLinesColumns } from "@/repositories/search/model/SupplierLinesColumns";
import { useTablePreferences } from "@cumulus/components";
import { type DataTableColumn, PopOverSorting, PopOverColumnChooser } from "@cumulus/components";

const { t, d } = useI18n();

const router = useRouter();
const route = useRoute();

const toast = useCumulusToast(useToast());
const supplierSearchResult = ref();
const selectedSupplier = ref<SupplierSearch>(new SupplierSearch());
const expandedRows = ref([]);

const props = defineProps<{
  suppliers: SupplierSearch[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sortOrder", value: number): void;
  (e: "refreshList"): void;
}>();

const supplierSearchColumns: DataTableColumn[] = [
  { field: "registeredDate", header: "supplier.registered", class: "w-1rem", sortable: true },
  { field: "supplierNumber", header: "supplier.number.label", class: "w-12", sortable: true },
  { field: "name", header: "supplier.search.header-name", sortable: true, class: "c-supplier-name" },
  {
    field: "phoneNumber",
    header: "supplier.search.header-phone-number",
    sortable: true,
    class: "c-supplier-phone-number",
  },
  { field: "email", header: "supplier.email", sortable: true },
  {
    field: "companyAddress.addressLines",
    header: "supplier.addresses.label",
    sortable: true,
    class: "c-supplier-addresses",
  },
  {
    field: "companyAddress.city",
    header: "common.address.city",
    sortable: true,
    class: "c-supplier-city w-2rem",
  },
  {
    field: "companyAddress.countryIso",
    header: "common.address.country",
    sortable: true,
    class: "c-supplier-country w-1rem",
  },
  { field: "purchasePermit", header: "supplier.supplier-status", sortable: true },
];
const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("supplierSearch", supplierSearchColumns, null);

const isShowedSorting = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const isShowedChooser = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

watch([() => props.suppliers, () => props.loading], ([suppliers, loading]) => {
  if (loading === false && suppliers.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(supplierSearchColumns.find((column) => column.field === SupplierLinesColumns.Name));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);
    emit("update:sortField", defaultSortField.value.field);
    emit("update:page", 1);
  });
};

const op = ref();

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = supplierSearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: SupplierSearch) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedSupplier.value?.id === data?.id },
  ];
};

const joinAddressLines = (address: string[]): string => {
  return address.join(", ");
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchSupplier(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openSearchSupplier(event.data.id);
};

const copyName = (name: string) => {
  navigator.clipboard.writeText(name);
};

const copyPhoneNumber = (phoneNumber: string) => {
  navigator.clipboard.writeText(phoneNumber);
};

const openSearchSupplier = (supplierId: string) => {
  supplierId != null && supplierId !== emptyUuid
    ? router.push({ name: "supplier-edit", params: { id: supplierId } })
    : toast.add({
        severity: "warn",
        summary: t("supplier.toast.open-supplier-failed"),
        detail: t("supplier.search-header.no-supplier-selected"),
      });
};

const focusSearchResult = (id = "") => {
  if (props.suppliers.length > 0 && supplierSearchResult.value) {
    const element = id === "" ? null : supplierSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }

    supplierSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onSupplierNumberClicked = (supplier: SupplierSearch) => {
  selectedSupplier.value = supplier;
  openSearchSupplier(supplier.id);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>

<style scoped lang="scss">
.c-supplier-number-text:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row .pi.pi-copy.c-copy-column-text-btn {
  font-size: 1.25rem;
  visibility: hidden;
}

tr.p-datatable-selectable-row td.c-supplier-phone-number:hover .c-copy-column-text-btn {
  visibility: visible;
}

tr.p-datatable-selectable-row td.c-supplier-name:hover .c-copy-column-text-btn {
  visibility: visible;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
