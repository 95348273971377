<template>
  <FloatLabel variant="on">
    <InputText
      :id="`contact-phone-number-${props.rowNumber}`"
      v-model="phoneNumberComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.phone-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :data-testid="`contact-phone-number-${props.rowNumber}`"
      type="tel"
      class="w-full"
      :invalid="val.phoneNumber.$error"
    />
    <label for="`contact-phone-number-${props.rowNumber}`">{{ t("supplier.phone-number") }}</label>
  </FloatLabel>
  <small v-if="val.phoneNumber.$error" class="p-error" data-testid="contact-phone-number-error">
    {{ val.phoneNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";

const props = defineProps<{
  phoneNumber: string;
  contact: SupplierContact;
  rowNumber: number;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string): void;
}>();

const phoneNumberComputed = computed<string>({
  get: () => {
    return props.phoneNumber ?? "";
  },
  set: (value) => {
    emit("update:phoneNumber", value);
  },
});

const { t } = useI18n();

const validateContacts = () => {
  if (props.contact.phoneNumber !== "") {
    //Valid
    return true;
  }

  if (
    props.contact.firstName === "" &&
    props.contact.lastName === "" &&
    props.contact.email === "" &&
    props.contact.phoneNumber === ""
  ) {
    //Valid
    return true;
  }

  if (props.contact.firstName !== "" || props.contact.lastName !== "") {
    if (props.contact.phoneNumber !== "" || props.contact.email !== "") {
      //Valid
      return true;
    }
  }

  //Not valid
  return false;
};

const rules = {
  phoneNumber: {
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
    required: helpers.withMessage(() => t("validations.phone-required"), validateContacts),
  },
};

const val = useVuelidate(rules, props);
</script>
